<template>
  <div class="flex flex-col gap-y-4">
    <slot name="title">
      <div class="flex items-center gap-x-4 px-4 md:px-10 lg:px-28">
        <h2
          v-if="titleOptions?.label"
          class="text-title-medium md:text-title-big lg:text-headline-small text-white opacity-[0.87]"
        >
          {{ $t(titleOptions.label) }}
        </h2>
        <NuxtLink
          v-if="getViewAllLocalePath"
          :to="getViewAllLocalePath"
          class="md:pt-1 flex items-center"
        >
          <span class="text-body-medium md:text-body-big text-white opacity-50">
            {{ viewAllLabel }}
          </span>
          <Icon
            name="IconChevronRight"
            size="20"
            :opacity="0.5"
            class="rtl:rotate-180"
          ></Icon>
        </NuxtLink>
      </div>
    </slot>
    <CarouselElement
      v-if="items?.length > 0"
      :element-id="carouselId"
      :items="items"
      :override-options="carouselOptions"
      :carousel-class="carouselClass"
      class="w-full"
      @isDragging="(e) => emit('isDragging', e)"
      @onHover="(val) => emit('onHover', val)"
      @onSlideChange="(e) => emit('on-slide-change', e)"
    >
      <template #slider-item="{ item, key, isHovered, carouselId }">
        <!-- bubble up the responsiblity to parent -->
        <slot
          name="slider-item"
          :item="item"
          :key="key"
          :is-hovered="isHovered"
          :carousel-id="carouselId"
        ></slot>
      </template>
      <template #emptyItems>
        <slot name="emptyItems"></slot>
      </template>
    </CarouselElement>
    <slot v-else name="emptyItems"> </slot>
  </div>
</template>

<script setup>
const route = useRoute();
const props = defineProps({
  carouselId: String,
  carouselType: String,
  items: {
    type: Array,
    required: true,
  },
  titleOptions: {
    type: Object,
    default: () => null,
  },
  carouselOptions: {
    type: Object,
    default: () => null,
  },
  apiOptions: {
    type: Object,
    required: true,
  },
  hideViewAllBtn: {
    type: Boolean,
    default: false,
  },
  carouselClass: String,
});

const emit = defineEmits(["isDragging", "onHover"]);

const localePath = useLocalePath();

const { t } = useI18n();

const getPageTypeFromNuxtMeta = route?.meta?.pageType
  ? `${route?.meta?.pageType}`
  : null;

const getViewAllLocalePath = computed(() => {
  if (!props.apiOptions || props.hideViewAllBtn) return "";

  // API config to session storage
  storeCarouselApiConfigsInSessionStorage();

  // Default
  if (getPageTypeFromNuxtMeta === null) {
    return buildNavigationPath();
  }

  // Custom path builder
  if (getPageTypeFromNuxtMeta !== null) {
    switch (props.carouselType) {
      case "thumbnail_with_overlay": {
        return localePath(
          `/${getPageTypeFromNuxtMeta}/categories/${props.apiOptions.query.external_category_id}`
        );
      }
      default: {
        return localePath(
          `/${getPageTypeFromNuxtMeta}/all/${props.apiOptions.query.external_category_id}`
        );
      }
    }
  }
});

const buildNavigationPath = () => {
  let externalCategoryId = props?.apiOptions?.query?.external_category_id;

  if (props.carouselId === "genres") {
    externalCategoryId = "genres";
  }

  switch (externalCategoryId) {
    case "genres":
    case "web_home_genres":
    case "web_shows_genres": {
      return localePath(
        `/genres/${props?.apiOptions?.query?.external_category_id}`
      );
    }
    case "web_all_sports": {
      return localePath(`/sports/categories/`);
    }
    // Kids page
    case "kids_reg_songs": {
      return localePath(`/kids/songs/`);
    }
    default: {
      return localePath(
        `/directory/${props?.apiOptions?.query?.external_category_id}`
      );
    }
  }
};

const storeCarouselApiConfigsInSessionStorage = () => {
  const storageId = `directory-${props?.apiOptions?.query?.external_category_id}`;
  sessionStorage.setItem("parentRoute", route.path);
  sessionStorage.setItem(storageId, JSON.stringify(props.apiOptions));
};

const { direction } = useCurrentLocale();

const viewAllLabel = computed(() => {
  try {
    if (titleOptions?.ctaLabel) {
      return t(titleOptions?.ctaLabel);
    }
    return t("View All");
  } catch (error) {
    return t("View All");
  }
});
</script>

<style lang="scss" scoped></style>
